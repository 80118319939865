import './style.css';
import ceicatCat from './metadata/ceicat-cat-logo.png';

import { Connection, PublicKey } from '@solana/web3.js';
import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';

const TOKEN_ADDRESS = new PublicKey("8PMJczfs9W8TDKiNBD85AuqxE8tWACCDeUwxpUeadL3j");
const REQUIRED_BALANCE = 20000 * 1e9;

let walletAddress = null;

const checkTokenBalance = async() => {
  try {
    const connection = new Connection("https://solana-mainnet.g.alchemy.com/v2/Fqd58LIvoj4jV8m7Sv68J1vhOUUELDw9");
    const userAddress = new PublicKey(walletAddress);
    const tokenAddress = await getAssociatedTokenAddress(TOKEN_ADDRESS, userAddress);
    const tokenAccount = await getAccount(connection, tokenAddress);
    return tokenAccount.amount >= REQUIRED_BALANCE;
  } catch(error) {
    console.error("Error checking token balance:", error);
    return false;
  }
};

const noAccess = document.createElement('div');
noAccess.id = "noAccessPage";

const noAccessText = document.createElement('span');

const noAccessBoldText = document.createElement('span'); 
noAccessBoldText.classList.add("boldText");
noAccessBoldText.innerHTML = `20,000 $CEICAT`;

noAccessText.innerHTML = `You need to hold atleast `;
noAccessText.appendChild(noAccessBoldText);
noAccessText.innerHTML += ` in your wallet to customize...`;

noAccess.appendChild(noAccessText);

const gatekeepCustomization = async() => {
  const hasAccess = await checkTokenBalance();
  if (!hasAccess) {
    createPage.appendChild(noAccess);
    noAccess.style.zIndex = "1000";
  } else {
    noAccess.remove();
  }
};

const truncateAddress = (addy) => {
  return `${addy.slice(0, 3)}...${addy.slice(-3)}`;
};

const connectWallet = async() => {
  try {
    const provider = window.solana;
    if (provider) {
      // disconnect logic
      if (walletAddress) {
        walletAddress = null;
        connectWalletBtn.innerHTML = `Connect Wallet`;
        connectWalletBtn.removeEventListener('mouseover', handleMouseOver);
        connectWalletBtn.removeEventListener('mouseout', handleMouseOut);
        gatekeepCustomization();
        return;
      }
      const response = await provider.connect();
      walletAddress = response.publicKey.toString();
      connectWalletBtn.innerHTML = `Connected<br>${truncateAddress(walletAddress)}`;
      connectWalletBtn.addEventListener('mouseover', handleMouseOver);
      connectWalletBtn.addEventListener('mouseout', handleMouseOut);
      await gatekeepCustomization();
    } else {
      alert("Please install a Solana wallet such as Phantom!");
    }
  } catch(error) {
    console.error("Error connecting wallet:", error);
  }
};

const handleMouseOver = () => {
  connectWalletBtn.innerHTML = `Disconnect Wallet`;
}
const handleMouseOut = () => {
  connectWalletBtn.innerHTML = `Connected<br>${truncateAddress(walletAddress)}`;
}

// background imports
import bg1img from './metadata/ASSETS/01_Ceiling/Burnt.webp';
import bg2img from './metadata/ASSETS/01_Ceiling/Dusty.webp';
import bg3img from './metadata/ASSETS/01_Ceiling/Sandy.webp';
import bg4img from './metadata/ASSETS/01_Ceiling/White.webp';
import bg5img from './metadata/ASSETS/01_Ceiling_2/Backroom.webp';
import bg6img from './metadata/ASSETS/01_Ceiling_2/Chart.webp';
import bg7img from './metadata/ASSETS/01_Ceiling_2/Cloud.webp';
import bg8img from './metadata/ASSETS/01_Ceiling_2/Coderain.webp';
import bg9img from './metadata/ASSETS/01_Ceiling_2/Da_Vinci.webp';
import bg10img from './metadata/ASSETS/01_Ceiling_2/Divine.webp';
import bg11img from './metadata/ASSETS/01_Ceiling_2/Milky_way.webp';
import bg12img from './metadata/ASSETS/01_Ceiling_2/Moonlanding.webp';
import bg13img from './metadata/ASSETS/01_Ceiling_2/Paranormal.webp';
import bg14img from './metadata/ASSETS/01_Ceiling_2/Universe.webp';

// finishing imports
import fin1img from './metadata/ASSETS/02_Finishing/1_Inch.webp';
import fin2img from './metadata/ASSETS/02_Finishing/Eco-Perforated.webp';
import fin3img from './metadata/ASSETS/02_Finishing/Perforated.webp';
import fin4img from './metadata/ASSETS/02_Finishing/Suspended.webp';
import fin5img from './metadata/ASSETS/02_Finishing/Timber.webp';
import fin6img from './metadata/ASSETS/02_Finishing/Undercon.webp';

// frame imports
import frm1img from './metadata/ASSETS/03_Frame/Aurora.webp';
import frm2img from './metadata/ASSETS/03_Frame/Burning.webp';
import frm3img from './metadata/ASSETS/03_Frame/Coderain.webp';
import frm4img from './metadata/ASSETS/03_Frame/Earth.webp';
import frm5img from './metadata/ASSETS/03_Frame/Eden.webp';
import frm6img from './metadata/ASSETS/03_Frame/Moon.webp';
import frm7img from './metadata/ASSETS/03_Frame/Pitch_Black.webp';
import frm8img from './metadata/ASSETS/03_Frame/Shady.webp';
import frm9img from './metadata/ASSETS/03_Frame/Stars.webp';
import frm10img from './metadata/ASSETS/03_Frame/Sunny_Day.webp';
import frm11img from './metadata/ASSETS/03_Frame/Thunder.webp';

// cat body imports
import cb1img from './metadata/ASSETS/04_Cat/American-Shorthair.webp';
import cb2img from './metadata/ASSETS/04_Cat/Ash.webp';
import cb3img from './metadata/ASSETS/04_Cat/Bengal.webp';
import cb4img from './metadata/ASSETS/04_Cat/Black.webp';
import cb5img from './metadata/ASSETS/04_Cat/Calico.webp';
import cb6img from './metadata/ASSETS/04_Cat/D-face.webp';
import cb7img from './metadata/ASSETS/04_Cat/Dusty.webp';
import cb8img from './metadata/ASSETS/04_Cat/Ginger.webp';
import cb9img from './metadata/ASSETS/04_Cat/Orange.webp';
import cb10img from './metadata/ASSETS/04_Cat/Ragamuffin.webp';
import cb11img from './metadata/ASSETS/04_Cat/Shiro.webp';
import cb12img from './metadata/ASSETS/04_Cat/Siamese.webp';
import cb13img from './metadata/ASSETS/04_Cat/Tabi.webp';
import cb14img from './metadata/ASSETS/04_Cat/Thugster.webp';

// mouth imports
import mth1img from './metadata/ASSETS/05_Mouth/Catnip.webp';
import mth2img from './metadata/ASSETS/05_Mouth/Cig.webp';
import mth3img from './metadata/ASSETS/05_Mouth/Drool.webp';
import mth4img from './metadata/ASSETS/05_Mouth/Earphones.webp';
import mth5img from './metadata/ASSETS/05_Mouth/Fangs.webp';
import mth6img from './metadata/ASSETS/05_Mouth/Fiat.webp';
import mth7img from './metadata/ASSETS/05_Mouth/Fishbone.webp';
import mth8img from './metadata/ASSETS/05_Mouth/HeartStopper.webp';
import mth9img from './metadata/ASSETS/05_Mouth/Jam.webp';
import mth10img from './metadata/ASSETS/05_Mouth/Joint.webp';
import mth11img from './metadata/ASSETS/05_Mouth/Joshua.webp';
import mth12img from './metadata/ASSETS/05_Mouth/liz.webp';
import mth13img from './metadata/ASSETS/05_Mouth/Mlem.webp';
import mth14img from './metadata/ASSETS/05_Mouth/Pink-Beanie.webp';
import mth15img from './metadata/ASSETS/05_Mouth/Pizza.webp';
import mth16img from './metadata/ASSETS/05_Mouth/Rabbit.webp';
import mth17img from './metadata/ASSETS/05_Mouth/Rainbow.webp';
import mth18img from './metadata/ASSETS/05_Mouth/Socks.webp';
import mth19img from './metadata/ASSETS/05_Mouth/Sol-Saga.webp';

// drip imports
import dr1img from './metadata/ASSETS/06_Drip/Angry.webp';
import dr2img from './metadata/ASSETS/06_Drip/Aviator.webp';
import dr3img from './metadata/ASSETS/06_Drip/Awaken.webp';
import dr4img from './metadata/ASSETS/06_Drip/Band-aid.webp';
import dr5img from './metadata/ASSETS/06_Drip/Bandana.webp';
import dr6img from './metadata/ASSETS/06_Drip/Battle Scar.webp';
import dr7img from './metadata/ASSETS/06_Drip/Black Balaclava.webp';
import dr8img from './metadata/ASSETS/06_Drip/Black Frames.webp';
import dr9img from './metadata/ASSETS/06_Drip/Black Hoodie.webp';
import dr10img from './metadata/ASSETS/06_Drip/Blusher.webp';
import dr11img from './metadata/ASSETS/06_Drip/BucketHat.webp';
import dr12img from './metadata/ASSETS/06_Drip/Cheddar.webp';
import dr13img from './metadata/ASSETS/06_Drip/Cool Shades.webp';
import dr14img from './metadata/ASSETS/06_Drip/Eyepatch.webp';
import dr15img from './metadata/ASSETS/06_Drip/Frog Mask.webp';
import dr16img from './metadata/ASSETS/06_Drip/Gang Balaclava.webp';
import dr17img from './metadata/ASSETS/06_Drip/Glock.webp';
import dr18img from './metadata/ASSETS/06_Drip/Greased up.webp';
import dr19img from './metadata/ASSETS/06_Drip/Hypnotized.webp';
import dr20img from './metadata/ASSETS/06_Drip/JakesEyeband.webp';
import dr21img from './metadata/ASSETS/06_Drip/Khajit.webp';
import dr22img from './metadata/ASSETS/06_Drip/LaurelWreath.webp';
import dr23img from './metadata/ASSETS/06_Drip/Lazor.webp';
import dr24img from './metadata/ASSETS/06_Drip/Marketwatch.webp';
import dr25img from './metadata/ASSETS/06_Drip/Mohawk.webp';
import dr26img from './metadata/ASSETS/06_Drip/Morpheus.webp';
import dr27img from './metadata/ASSETS/06_Drip/multicolored.webp';
import dr28img from './metadata/ASSETS/06_Drip/Mutant Visor.webp';
import dr29img from './metadata/ASSETS/06_Drip/Neo Chibi.webp';
import dr30img from './metadata/ASSETS/06_Drip/NVG.webp';
import dr31img from './metadata/ASSETS/06_Drip/Over 9000.webp';
import dr32img from './metadata/ASSETS/06_Drip/PartyHat.webp';
import dr33img from './metadata/ASSETS/06_Drip/Pink Ribbon.webp';
import dr34img from './metadata/ASSETS/06_Drip/Pit Viper.webp';
import dr35img from './metadata/ASSETS/06_Drip/Pomelo.webp';
import dr36img from './metadata/ASSETS/06_Drip/Red Beam.webp';
import dr37img from './metadata/ASSETS/06_Drip/Red Frames.webp';
import dr38img from './metadata/ASSETS/06_Drip/Red Headband.webp';
import dr39img from './metadata/ASSETS/06_Drip/Red Horn.webp';
import dr40img from './metadata/ASSETS/06_Drip/Sleepy Mask.webp';
import dr41img from './metadata/ASSETS/06_Drip/Sproto Pilled.webp';
import dr42img from './metadata/ASSETS/06_Drip/Sus.webp';
import dr43img from './metadata/ASSETS/06_Drip/Third Eye.webp';
import dr44img from './metadata/ASSETS/06_Drip/Trucker.webp';
import dr45img from './metadata/ASSETS/06_Drip/Vibrating.webp';
import dr46img from './metadata/ASSETS/06_Drip/Vision Pro.webp';
import dr47img from './metadata/ASSETS/06_Drip/Yellow Beam.webp';
import dr48img from './metadata/ASSETS/06_Drip/Zorro.webp';

const header = document.querySelector('header');

const gotoHomeBtn = document.createElement('button');
gotoHomeBtn.innerHTML = `Go Back Home`;
header.appendChild(gotoHomeBtn);

const headerImg = document.createElement('img');
headerImg.src = ceicatCat; 
header.appendChild(headerImg);

const connectWalletBtn = document.createElement('button');
connectWalletBtn.innerHTML = `Connect Wallet`;
header.appendChild(connectWalletBtn);

// PFP Display
const displayPage = document.getElementById('display-page');

const preview = document.createElement('div');
preview.classList.add('preview');
displayPage.appendChild(preview);

const canvas = document.createElement('canvas');
canvas.id = "canvas";
canvas.width = 1024;
canvas.height = 1024;
preview.appendChild(canvas);

const displayAction = document.createElement('div');
displayAction.classList.add("action");

const genRandomBtn = document.createElement('button');
genRandomBtn.id = "genRandomBtn";
genRandomBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
    style="width: 20px; height: 20px;"> 
    <path d="M5 3H3v18h18V3H5zm14 2v14H5V5h14zM9 7H7v2h2V7zm6 0h2v2h-2V7zm-6 8H7v2h2v-2zm6 0h2v2h-2v-2zm-2-4h-2v2h2v-2z" fill="currentColor"/> 
  </svg> Generate Random`;
displayAction.appendChild(genRandomBtn);

const downloadBtn = document.createElement('button');
downloadBtn.id = "downloadBtn";
downloadBtn.innerHTML = `
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
    style="width: 20px; height: 20px;"> 
    <path d="M13 17V3h-2v10H9v-2H7v2h2v2h2v2h2zm8 2v-4h-2v4H5v-4H3v6h18v-2zm-8-6v2h2v-2h2v-2h-2v2h-2z" fill="currentColor"/> 
  </svg> Download`;
displayAction.appendChild(downloadBtn);

displayPage.appendChild(displayAction);

// Customization Options
const createPage = document.getElementById('create-page');

const createPageTitle = document.createElement('h1');
createPageTitle.innerHTML = `Customize your ceiling cat...`;
createPage.appendChild(createPageTitle);

// backdrop
const background = document.createElement('div');
const backgroundText = document.createElement('span');
backgroundText.innerHTML = `Backdrop`;
const backgroundContainer = document.createElement('div');
backgroundContainer.classList.add("container");

const bgLeftBtn = document.createElement('button');
bgLeftBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M20 11v2H8v2H6v-2H4v-2h2V9h2v2h12zM10 7H8v2h2V7zm0 0h2V5h-2v2zm0 10H8v-2h2v2zm0 0h2v2h-2v-2z" fill="currentColor"/> 
  </svg>`
backgroundContainer.appendChild(bgLeftBtn);

const bgScroll = document.createElement('div');
bgScroll.classList.add("scrollContainer");

const bg1 = document.createElement('img');
bg1.src = bg1img;
const bg2 = document.createElement('img');
bg2.src = bg2img;
const bg3 = document.createElement('img');
bg3.src = bg3img;
const bg4 = document.createElement('img');
bg4.src = bg4img; 
const bg5 = document.createElement('img');
bg5.src = bg5img;
const bg6 = document.createElement('img');
bg6.src = bg6img;
const bg7 = document.createElement('img');
bg7.src = bg7img;
const bg8 = document.createElement('img');
bg8.src = bg8img; 
const bg9 = document.createElement('img');
bg9.src = bg9img;
const bg10 = document.createElement('img');
bg10.src = bg10img;
const bg11 = document.createElement('img');
bg11.src = bg11img;
const bg12 = document.createElement('img');
bg12.src = bg12img;
const bg13 = document.createElement('img');
bg13.src = bg13img; 
const bg14 = document.createElement('img');
bg14.src = bg14img;

const bgArray = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11, bg12, bg13, bg14];
bgArray.forEach(option => {
  option.classList.add("not-selected");
  bgScroll.appendChild(option);
});

backgroundContainer.appendChild(bgScroll);

const bgRightBtn = document.createElement('button');
bgRightBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M4 11v2h12v2h2v-2h2v-2h-2V9h-2v2H4zm10-4h2v2h-2V7zm0 0h-2V5h2v2zm0 10h2v-2h-2v2zm0 0h-2v2h2v-2z" fill="currentColor"/> 
  </svg>`;
backgroundContainer.appendChild(bgRightBtn);

background.appendChild(backgroundText);
background.appendChild(backgroundContainer);
createPage.appendChild(background);

// finishing
const finishing = document.createElement('div');
const finishingText = document.createElement('span');
finishingText.innerHTML = `Finishing`;
const finishingContainer = document.createElement('div');
finishingContainer.classList.add("container");

const finLeftBtn = document.createElement('button');
finLeftBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M20 11v2H8v2H6v-2H4v-2h2V9h2v2h12zM10 7H8v2h2V7zm0 0h2V5h-2v2zm0 10H8v-2h2v2zm0 0h2v2h-2v-2z" fill="currentColor"/> 
  </svg>`
finishingContainer.appendChild(finLeftBtn);

const finScroll = document.createElement('div');
finScroll.classList.add("scrollContainer");

const fin1 = document.createElement('img');
fin1.src = fin1img;
const fin2 = document.createElement('img');
fin2.src = fin2img;
const fin3 = document.createElement('img');
fin3.src = fin3img;
const fin4 = document.createElement('img');
fin4.src = fin4img; 
const fin5 = document.createElement('img');
fin5.src = fin5img;
const fin6 = document.createElement('img');
fin6.src = fin6img;

const finArray = [fin1, fin2, fin3, fin4, fin5, fin6];
finArray.forEach(option => {
  option.classList.add("not-selected");
  finScroll.appendChild(option);
});
finishingContainer.appendChild(finScroll);

const finRightBtn = document.createElement('button');
finRightBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M4 11v2h12v2h2v-2h2v-2h-2V9h-2v2H4zm10-4h2v2h-2V7zm0 0h-2V5h2v2zm0 10h2v-2h-2v2zm0 0h-2v2h2v-2z" fill="currentColor"/> 
  </svg>`;
finishingContainer.appendChild(finRightBtn);

finishing.appendChild(finishingText);
finishing.appendChild(finishingContainer);
createPage.appendChild(finishing);

// frame
const frame = document.createElement('div');
const frameText = document.createElement('span');
frameText.innerHTML = `Frame`;
const frameContainer = document.createElement('div');
frameContainer.classList.add("container");

const frmLeftBtn = document.createElement('button');
frmLeftBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M20 11v2H8v2H6v-2H4v-2h2V9h2v2h12zM10 7H8v2h2V7zm0 0h2V5h-2v2zm0 10H8v-2h2v2zm0 0h2v2h-2v-2z" fill="currentColor"/> 
  </svg>`
frameContainer.appendChild(frmLeftBtn);

const frmScroll = document.createElement('div');
frmScroll.classList.add("scrollContainer");

const frm1 = document.createElement('img');
frm1.src = frm1img;
const frm2 = document.createElement('img');
frm2.src = frm2img;
const frm3 = document.createElement('img');
frm3.src = frm3img;
const frm4 = document.createElement('img');
frm4.src = frm4img; 
const frm5 = document.createElement('img');
frm5.src = frm5img;
const frm6 = document.createElement('img');
frm6.src = frm6img;
const frm7 = document.createElement('img');
frm7.src = frm7img;
const frm8 = document.createElement('img');
frm8.src = frm8img;
const frm9 = document.createElement('img');
frm9.src = frm9img; 
const frm10 = document.createElement('img');
frm10.src = frm10img;
const frm11 = document.createElement('img');
frm11.src = frm11img;

const frmArray = [frm1, frm2, frm3, frm4, frm5, frm6, frm7, frm8, frm9, frm10, frm11];
frmArray.forEach(option => {
  option.classList.add("not-selected");
  frmScroll.appendChild(option);
});
frameContainer.appendChild(frmScroll);

const frmRightBtn = document.createElement('button');
frmRightBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M4 11v2h12v2h2v-2h2v-2h-2V9h-2v2H4zm10-4h2v2h-2V7zm0 0h-2V5h2v2zm0 10h2v-2h-2v2zm0 0h-2v2h2v-2z" fill="currentColor"/> 
  </svg>`;
frameContainer.appendChild(frmRightBtn);

frame.appendChild(frameText);
frame.appendChild(frameContainer);
createPage.appendChild(frame);

// cat body
const catbody = document.createElement('div');
const catbodyText = document.createElement('span');
catbodyText.innerHTML = `Cat-Bod`;
const catbodyContainer = document.createElement('div');
catbodyContainer.classList.add("container");

const cbLeftBtn = document.createElement('button');
cbLeftBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M20 11v2H8v2H6v-2H4v-2h2V9h2v2h12zM10 7H8v2h2V7zm0 0h2V5h-2v2zm0 10H8v-2h2v2zm0 0h2v2h-2v-2z" fill="currentColor"/> 
  </svg>`
catbodyContainer.appendChild(cbLeftBtn);

const cbScroll = document.createElement('div');
cbScroll.classList.add("scrollContainer");

const cb1 = document.createElement('img');
cb1.src = cb1img;
const cb2 = document.createElement('img');
cb2.src = cb2img;
const cb3 = document.createElement('img');
cb3.src = cb3img;
const cb4 = document.createElement('img');
cb4.src = cb4img; 
const cb5 = document.createElement('img');
cb5.src = cb5img;
const cb6 = document.createElement('img');
cb6.src = cb6img;
const cb7 = document.createElement('img');
cb7.src = cb7img;
const cb8 = document.createElement('img');
cb8.src = cb8img;
const cb9 = document.createElement('img');
cb9.src = cb9img; 
const cb10 = document.createElement('img');
cb10.src = cb10img;
const cb11 = document.createElement('img');
cb11.src = cb11img;
const cb12 = document.createElement('img');
cb12.src = cb12img;
const cb13 = document.createElement('img');
cb13.src = cb13img; 
const cb14 = document.createElement('img');
cb14.src = cb14img;

const cbArray = [cb1, cb2, cb3, cb4, cb5, cb6, cb7, cb8, cb9, cb10, cb11, cb12, cb13, cb14];
cbArray.forEach(option => {
  option.classList.add("not-selected");
  cbScroll.appendChild(option);
});
catbodyContainer.appendChild(cbScroll);

const cbRightBtn = document.createElement('button');
cbRightBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M4 11v2h12v2h2v-2h2v-2h-2V9h-2v2H4zm10-4h2v2h-2V7zm0 0h-2V5h2v2zm0 10h2v-2h-2v2zm0 0h-2v2h2v-2z" fill="currentColor"/> 
  </svg>`;
catbodyContainer.appendChild(cbRightBtn);

catbody.appendChild(catbodyText);
catbody.appendChild(catbodyContainer);
createPage.appendChild(catbody);

// mouth
const mouth = document.createElement('div');
const mouthText = document.createElement('span');
mouthText.innerHTML = `Mouth`;
const mouthContainer = document.createElement('div');
mouthContainer.classList.add("container");

const mthLeftBtn = document.createElement('button');
mthLeftBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M20 11v2H8v2H6v-2H4v-2h2V9h2v2h12zM10 7H8v2h2V7zm0 0h2V5h-2v2zm0 10H8v-2h2v2zm0 0h2v2h-2v-2z" fill="currentColor"/> 
  </svg>`
mouthContainer.appendChild(mthLeftBtn);

const mthScroll = document.createElement('div');
mthScroll.classList.add("scrollContainer");
mthScroll.classList.add("two-row-scroll");

const mth1 = document.createElement('img');
mth1.src = mth1img;
const mth2 = document.createElement('img');
mth2.src = mth2img;
const mth3 = document.createElement('img');
mth3.src = mth3img;
const mth4 = document.createElement('img');
mth4.src = mth4img; 
const mth5 = document.createElement('img');
mth5.src = mth5img;
const mth6 = document.createElement('img');
mth6.src = mth6img;
const mth7 = document.createElement('img');
mth7.src = mth7img;
const mth8 = document.createElement('img');
mth8.src = mth8img;
const mth9 = document.createElement('img');
mth9.src = mth9img; 
const mth10 = document.createElement('img');
mth10.src = mth10img;
const mth11 = document.createElement('img');
mth11.src = mth11img;
const mth12 = document.createElement('img');
mth12.src = mth12img;
const mth13 = document.createElement('img');
mth13.src = mth13img;
const mth14 = document.createElement('img');
mth14.src = mth14img;
const mth15 = document.createElement('img');
mth15.src = mth15img;
const mth16 = document.createElement('img');
mth16.src = mth16img;
const mth17 = document.createElement('img');
mth17.src = mth17img;
const mth18 = document.createElement('img');
mth18.src = mth18img;
const mth19 = document.createElement('img');
mth19.src = mth19img;

const mthArray = [mth1, mth2, mth3, mth4, mth5, mth6, mth7, mth8, mth9, mth10, mth11, mth12, mth13, mth14, mth15, mth16, mth17, mth18, mth19];
mthArray.forEach(option => {
  option.classList.add("not-selected");
  mthScroll.appendChild(option);
});
mouthContainer.appendChild(mthScroll);

const mthRightBtn = document.createElement('button');
mthRightBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M4 11v2h12v2h2v-2h2v-2h-2V9h-2v2H4zm10-4h2v2h-2V7zm0 0h-2V5h2v2zm0 10h2v-2h-2v2zm0 0h-2v2h2v-2z" fill="currentColor"/> 
  </svg>`;
mouthContainer.appendChild(mthRightBtn);

mouth.appendChild(mouthText);
mouth.appendChild(mouthContainer);
createPage.appendChild(mouth);

// drip
const drip = document.createElement('div');
const dripText = document.createElement('span');
dripText.innerHTML = `Drip`;
const dripContainer = document.createElement('div');
dripContainer.classList.add("container");

const drLeftBtn = document.createElement('button');
drLeftBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M20 11v2H8v2H6v-2H4v-2h2V9h2v2h12zM10 7H8v2h2V7zm0 0h2V5h-2v2zm0 10H8v-2h2v2zm0 0h2v2h-2v-2z" fill="currentColor"/> 
  </svg>`
dripContainer.appendChild(drLeftBtn);

const drScroll = document.createElement('div');
drScroll.classList.add("scrollContainer");
drScroll.classList.add("third-row-scroll");

const dr1 = document.createElement('img');
dr1.src = dr1img;
const dr2 = document.createElement('img');
dr2.src = dr2img;
const dr3 = document.createElement('img');
dr3.src = dr3img;
const dr4 = document.createElement('img');
dr4.src = dr4img; 
const dr5 = document.createElement('img');
dr5.src = dr5img;
const dr6 = document.createElement('img');
dr6.src = dr6img;
const dr7 = document.createElement('img');
dr7.src = dr7img;
const dr8 = document.createElement('img');
dr8.src = dr8img; 
const dr9 = document.createElement('img');
dr9.src = dr9img;
const dr10 = document.createElement('img');
dr10.src = dr10img;
const dr11 = document.createElement('img');
dr11.src = dr11img;
const dr12 = document.createElement('img');
dr12.src = dr12img;
const dr13 = document.createElement('img');
dr13.src = dr13img;
const dr14 = document.createElement('img');
dr14.src = dr14img;
const dr15 = document.createElement('img');
dr15.src = dr15img;
const dr16 = document.createElement('img');
dr16.src = dr16img;
const dr17 = document.createElement('img');
dr17.src = dr17img;
const dr18 = document.createElement('img');
dr18.src = dr18img;
const dr19 = document.createElement('img');
dr19.src = dr19img;
const dr20 = document.createElement('img');
dr20.src = dr20img;
const dr21 = document.createElement('img');
dr21.src = dr21img;
const dr22 = document.createElement('img');
dr22.src = dr22img;
const dr23 = document.createElement('img');
dr23.src = dr23img;
const dr24 = document.createElement('img');
dr24.src = dr24img;
const dr25 = document.createElement('img');
dr25.src = dr25img;
const dr26 = document.createElement('img');
dr26.src = dr26img;
const dr27 = document.createElement('img');
dr27.src = dr27img;
const dr28 = document.createElement('img');
dr28.src = dr28img;
const dr29 = document.createElement('img');
dr29.src = dr29img;
const dr30 = document.createElement('img');
dr30.src = dr30img;
const dr31 = document.createElement('img');
dr31.src = dr31img;
const dr32 = document.createElement('img');
dr32.src = dr32img;
const dr33 = document.createElement('img');
dr33.src = dr33img;
const dr34 = document.createElement('img');
dr34.src = dr34img;
const dr35 = document.createElement('img');
dr35.src = dr35img;
const dr36 = document.createElement('img');
dr36.src = dr36img;
const dr37 = document.createElement('img');
dr37.src = dr37img;
const dr38 = document.createElement('img');
dr38.src = dr38img;
const dr39 = document.createElement('img');
dr39.src = dr39img;
const dr40 = document.createElement('img');
dr40.src = dr40img;
const dr41 = document.createElement('img');
dr41.src = dr41img;
const dr42 = document.createElement('img');
dr42.src = dr42img;
const dr43 = document.createElement('img');
dr43.src = dr43img;
const dr44 = document.createElement('img');
dr44.src = dr44img;
const dr45 = document.createElement('img');
dr45.src = dr45img;
const dr46 = document.createElement('img');
dr46.src = dr46img;
const dr47 = document.createElement('img');
dr47.src = dr47img;
const dr48 = document.createElement('img');
dr48.src = dr48img;

const drArray = [dr1, dr2, dr3, dr4, dr5, dr6, dr7, dr8, dr9, dr10, dr11, dr12, dr13, dr14, dr15, dr16, dr17, dr18, dr19, dr20, dr21, dr22, dr23, dr24, dr25, dr26, dr27, dr28, dr29, dr30, dr31, dr32, dr33, dr34, dr35, dr36, dr37, dr38, dr39, dr40, dr41, dr42, dr43, dr44, dr45, dr46, dr47, dr48];
drArray.forEach(option => {
  option.classList.add("not-selected");
  drScroll.appendChild(option);
});

dripContainer.appendChild(drScroll);

const drRightBtn = document.createElement('button');
drRightBtn.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  style="width: 20px; height: 20px; margin-left: -10px;"> 
  <path d="M4 11v2h12v2h2v-2h2v-2h-2V9h-2v2H4zm10-4h2v2h-2V7zm0 0h-2V5h2v2zm0 10h2v-2h-2v2zm0 0h-2v2h2v-2z" fill="currentColor"/> 
  </svg>`;
dripContainer.appendChild(drRightBtn);

drip.appendChild(dripText);
drip.appendChild(dripContainer);
createPage.appendChild(drip);

document.addEventListener('DOMContentLoaded', function() {

  connectWalletBtn.addEventListener('click', connectWallet);

  gotoHomeBtn.addEventListener('click', function() {
    window.open('https://www.ceicatsol.com/', '_self');
  });

  function handleScrollBtn() {
    const scrollStep = 200;

    const leftBtns = [bgLeftBtn, finLeftBtn, frmLeftBtn, cbLeftBtn, mthLeftBtn, drLeftBtn];
    const rightBtns = [bgRightBtn, finRightBtn, frmRightBtn, cbRightBtn, mthRightBtn, drRightBtn];
    const containers = [bgScroll, finScroll, frmScroll, cbScroll, mthScroll, drScroll];
    
    leftBtns.forEach((leftBtn, index) => {
      leftBtn.addEventListener('click', function() {
        containers[index].scrollBy({ left: -scrollStep, behavior: 'smooth' });
        updateBtnOpacity(index); 
      }); 
    });

    rightBtns.forEach((rightBtn, index) => {
      rightBtn.addEventListener('click', function() {
        containers[index].scrollBy({ left: scrollStep, behavior: 'smooth' });
        updateBtnOpacity(index);
      }); 
    });

    containers.forEach((container, index) => {
      container.addEventListener('scroll', function() {
        updateBtnOpacity(index);  
      });
      // set initial opacity
      updateBtnOpacity(index);
    });

    function updateBtnOpacity(index) {
      const maxScrollLeft = containers[index].scrollWidth - containers[index].clientWidth;

      if (containers[index].scrollLeft <= 0) {
        leftBtns[index].style.opacity = "0.44";
        rightBtns[index].style.opacity = "1.0";
      } else if (containers[index].scrollLeft >= maxScrollLeft) {
        leftBtns[index].style.opacity = "1.0";
        rightBtns[index].style.opacity = "0.44";
      } else {
        leftBtns[index].style.opacity = "1.0";
        rightBtns[index].style.opacity = "1.0";
      }
    }
  }
  handleScrollBtn();

  function handleCustomization() {
    const optionsChronology = [bgArray, finArray, frmArray, cbArray, mthArray, drArray];

    const ctx = canvas.getContext('2d');
    function updateCanvas() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      optionsChronology.forEach(category => {
        const selected = category.find(img => img.classList.contains("selected"));
        if (selected) {
          ctx.drawImage(selected, 0, 0, canvas.width, canvas.height);
        }
      });
    }

    optionsChronology.forEach(category => {
      category.forEach(option => {
        option.addEventListener('click', function() {
          if (option.classList.contains("selected")) {
            option.classList.remove("selected");
          } else {
            category.forEach(option => option.classList.remove("selected"));
            option.classList.add("selected");
          }
          updateCanvas();
        });
      }); 
    });

    genRandomBtn.addEventListener('click', function() {
      optionsChronology.forEach(category => {
        category.forEach(option => option.classList.remove("selected"));

        const randomChoice = Math.floor(Math.random() * category.length);
        const selectedOption = category[randomChoice];
        selectedOption.classList.add("selected");
      });
      updateCanvas();
    });

    downloadBtn.addEventListener('click', function() {
      const getRand = Math.floor(100000 + Math.random() * 900000); // gen random 6-digit number for file name
      const getDate = new Date();
      const getDateFormat = getDate.toISOString().split('T')[0]; // formats the date as YYYY-MM-DD
      const fileName = `${getRand}-${getDateFormat}.png`;
      const link = document.createElement('a');
      link.download = fileName;
      link.href = canvas.toDataURL();
      link.click();
    });

    gatekeepCustomization();
  }
  handleCustomization();

});